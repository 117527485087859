<template>
  <div class="all-list">
    <van-nav-bar title="加油润滑" left-text="返回" fixed left-arrow @click-left="goBack">
      <template #right>
        <van-icon v-if="finnshWeixin" name="scan" size="18" @click="qrCode" />
      </template>
    </van-nav-bar>
    <van-search class="search" placeholder="请输入搜索关键词" @search="onSearch" />
    <van-tabs v-model:active="activeName" class="list-tab" @click="handleClick">
      <van-tab name="99" :title="'全部' + '(' + total + ')' "></van-tab>
      <van-tab name="1" :title="'待办' + '(' + todo + ')'"></van-tab>
      <van-tab name="2" :title="'完成' + '(' + done + ')'"></van-tab>
      <van-tab name="10" :title="'超时' + '(' + timeout + ')'"></van-tab>
      <van-tab name="20" :title="'待班长审核' + '(' + to_monitor + ')'"></van-tab>
      <van-tab name="30" :title="'待工程师审核' + '(' + to_engineer + ')'"></van-tab>
      <van-tab name="100" :title="'待执行（超时）' + '(' + timeout_todo + ')'"></van-tab>
      <van-tab name="200" :title="'完成（超时）' + '(' + timeout_done + ')'"></van-tab>
      <van-tab name="2000" :title="'待班长审核（超时）' + '(' + timeout_to_monitor + ')'"></van-tab>
      <van-tab name="3000" :title="'待工程师审核（超时）' + '(' + timeout_to_engineer + ')'"></van-tab>
      <van-tab name="666" :title="'扫码' + '(' + m_total + ')'"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="isRefresh" head-height="80" distance="80"  @refresh="onRefresh">
      <van-list class="index-listPlus" v-model="loading" :finished="isFinished" :finished-text="finishedText" @load="loadMore">
      <van-cell class="bq-list" v-for="item in list" :key="item.index" @click="goDetail(item.id)">
        <van-swipe-cell>
          <van-row type="flex" class="tag">
            <van-col>
              <van-row type="flex">
                <van-col>
                  <div>
                    <div class="item-title">
                      <van-tag v-if="item.status && item.status !== '完成' && item.status !== '超时'" type="primary">{{ item.status }}</van-tag>
                      <van-tag v-if="item.status === '完成'" type="success">{{ item.status }}</van-tag>
                      <van-tag v-if="item.status === '超时'" type="danger">{{ item.status }}</van-tag>
                      {{ item.equipment }} --{{ item.coding}}
                    </div>
                  </div>
                </van-col>
              </van-row>
            </van-col>
          </van-row>
          <div style="padding: 8px">
            <van-row type="flex" class="details">
              <van-col>{{ item.member_list}}</van-col>
            </van-row>
            <van-row type="flex" class="details flex">
              <van-col>{{ item.arrange_time}}</van-col>
              <van-col>周期：{{ item.period}}{{ item.period_unit }}</van-col>
            </van-row>
          </div>
        </van-swipe-cell>
      </van-cell>
    </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import API from '@/api/workorder/pm'
import wx from 'weixin-js-sdk'
import wxApi from '@/api/weixin/weixin'
import Cookies from 'js-cookie'
import format from "@/utils/day"
import { Toast } from "vant";
export default {
  name: "projectPage",
  inject: ['goUrl', 'reload'],

  data() {
    return {
      activeName: '99',
      list: [],
      value:'',
      loading: false,
      isFinished: false,
      isRefresh: false,
      finishedText:'加载完成',
      isLoading: false,
      finnshWeixin: false,
      total: 0,
      todo: 0,
      done: 0,
      timeout: 0,
      m_total: 0,
      to_monitor: 0,
      to_engineer: 0,
      timeout_todo: 0,
      timeout_done: 0,
      timeout_to_monitor: 0,
      timeout_to_engineer: 0,
      countAlready: false,
      query: {
        status: null,
        plan_category: 4,
        page: 0,
        limit: 5,
      }
    }
  },
  created() {
    this.getWxConfig()
  },
  methods: {
    index() {
      this.isLoading = true
      if (this.isRefresh) {
        this.list = []
        this.query.page = 0
        this.isRefresh = false
        this.isFinished = false
      }
      this.toTop()
      API.getList(this.query).then(res => {
        this.list.push(...res.data.list.data)
        if (this.list.length >= res.data.list.total) {
          this.isFinished = true
        }
        if(!this.countAlready){
          this.total = res.data.total
          this.todo = res.data.todo
          this.done = res.data.done
          this.timeout = res.data.timeout
          this.to_monitor = res.data.to_monitor
          this.to_engineer = res.data.to_engineer
          this.timeout_todo =res.data.timeout_todo
          this.timeout_done =res.data.timeout_done
          this.timeout_to_monitor = res.data.timeout_to_monitor
          this.timeout_to_engineer = res.data.timeout_to_engineer
          this.countAlready = true
        }
      })
      this.loading = false
      this.isLoading = false
    },
    loadMore() {
      if(!this.isLoading){
        this.query.page += 1
        this.index()
      }
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    handleClick(name) {
      if(name === '99'){
        this.query.status = null
        this.list = []
        this.query.page = 0
        this.index()
      } else if (name === '666'){
        this.query.status = null
        this.list = []
      } else {
        this.query.status = parseInt(name)
        this.list = []
        this.query.page = 0
        this.index()
      }
    },
    goBack() {
      this.goUrl('/index')
    },
    onSearch() {
      this.query.page = 0
      this.list = []
      this.index()
    },
    goDetail(id){
      const url = `/workorder/pm4/edit/${id}`
      this.goUrl(url)
    },
    onRefresh() {
      this.index()
    },
    getWxConfig() {
      const url = (window.location.href).split('#')[0]
      const _this = this
      wxApi.wxJSTicket({ url }).then(res =>{
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: res.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature,// 必填，签名
          jsApiList: ['checkJsApi','scanQRCode'] // 必填，需要使用的 JS 接口列表
        })
        wx.ready(function(){
          console.log(123)
          _this.finnshWeixin = true
        })
      })
    },
    qrCode() {
      const _this = this
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          const str = res.resultStr
          const eq = str.split('|')
          if(eq[0] !== 'e') {
            Toast('这不是设备')
          } else {
            localStorage.clear()
            _this.activeName = '666'
            API.getList({ equipment_coding: eq[1], plan_category: 4 }).then(res => {
              _this.list = []
              _this.m_total = res.data.total
              _this.list.push(...res.data.list.data)
              const needArr = []
              _this.list.forEach(item => {
                needArr.push(item.id)
              })
              const keyVal = JSON.stringify(needArr)
              let expires = new Date(new Date() * 1 + 600 * 1000);
              const date = new Date()
              const qrcode_time = format((date, 'YYYY-MM-DD HH:mm:ss'))
              Cookies.set('pmsKey', keyVal, { expires: expires })
              Cookies.set('qrcode', qrcode_time, { expires: expires })
              if(_this.list.length === 1) {
                const id = _this.list[0].id
                const url = `/workorder/pm1/edit/${id}`
                _this.goUrl(url)
              }
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped>
  .flex{
    display: flex;
    justify-content: space-between;
  }
</style>
